/* You can add global styles to this file, and also import other style files */

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import "primeicons/primeicons.css";

/* Order */
@layer reset, primeng;


/* Reset CSS */
@layer reset {
  .qr-container svg {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
    max-height: 100%;
  }

  .p-sortable-column {
    .p-icon-wrapper {
      visibility: hidden;
    }
  }

  .p-sortable-column:hover {
    .p-icon-wrapper {
      visibility: visible;
    }
  }

  .p-multiselect {
    width: 100%;
  }

  .p-breadcrumb {
    border-radius: 0;
  }

  .p-select-list,
  .p-select-label {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .p-selectbutton {
    .p-button {
      font-size: 14px;
    }
  }

  .p-chips {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
    height: 100%;

    span {
      font-size: 12px;
    }

    max-height: 34.1px;
  }

  .p-calendar {
    width: 100%;
  }

  .p-paginator {
    padding: 0;
    margin: 4px 0;
    font-size: 10px;

    button {
      font-size: 13px;
      border-radius: 4px;
      border-color: red;
      height: 28px;
      width: 20px;
    }

    .p-paginator-current {
      font-size: 12px;
    }

    .p-paginator-rpp-options {
      margin: 0;
      padding: 0;
    }
  }

  .p-datatable {
    .p-paginator-bottom {
      border-width: 0 !important;
    }
  }

  .p-datatable-header {
    border-width: 0 !important;
  }

  .p-tooltip-text {
    font-size: 12px;
  }

  textarea {
    font-size: 12px;
  }

  .p-fileupload {
    .p-button {
      border: 1px solid #8d9696;
      background-color: transparent;
      color: #7b8383;
      text-align: center;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;

      .pi {
        margin-right: 0.5rem;
      }
    }

    .p-button:hover {
      background-color: #6366f1;
      color: white;
    }
  }

  .p-inputtext {
    // padding: 6px 10px;
    width: 100%;
    height: 30px;
    font-size: 12px;
    line-height: 16px;
  }

  .p-select {
    width: 100%;

    span {
      padding: 6px 10px;
      font-size: 12px;
    }
  }

  .p-accordioncontent,
  .p-accordionheader,
  .p-accordioncontent-content {
    padding-right: 0;
    padding-left: 0;
  }


  .p-error {
    font-size: 12.5px;
    color: var(--p-red-400);
  }

  .p-password {
    width: 100%;
  }

  // .select-contract {
  //   width: 280px;
  // }
  .p-paginator-rpp-dropdown {
    width: 120px;
  }
}

//indigo, green, cyan, lime, noir, pink, purple, teal, blue, amber
:root {
  --background-global {
    background-color: #18181b;

    // &.theme-white {
    //   background-color: #ffffff;
    // }
  }
}

html {
  font-size: 16px;
}


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  user-select: none;
}

[aria-label] {
  user-select: text;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::ng-deep .p-datatable-wrapper {
  max-height: calc(100vh - 400px);
  overflow: auto;
}

::ng-deep .p-datatable-table {
  flex-grow: 1;
  /* Asegura que las filas ocupen todo el espacio disponible */
}

.p-datatable {
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  overflow: auto;
}

.p-datatable-table-container {
  //height: 100%;
  overflow-x: auto;
}


:ng-deep .p-paginator {
  flex-shrink: 0;
  /* Evita que la paginación se encoja o se mueva */
}

.table-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

tr:hover {
  .buttons {
    visibility: visible;
  }
}

.buttons {
  visibility: hidden;
}

.boton-sidebar {
  width: 100%;
}

.p-inputgroupaddon {
  font-size: 12px;
  line-height: 16px;
  height: 33.8px;
  font-weight: 700;
}

.dark {
  background-color: #3f3f46;

  .shadow-1 {
    box-shadow:
      0px 1px 7px rgba(0, 0, 0, 0.1),
      0px 4px 5px -2px rgba(0, 0, 0, 0.12),
      0px 10px 15px -5px rgba(0, 0, 0, 0.2);
  }

  .shadow-2 {
    box-shadow:
      0px 9px 46px 8px rgba(0, 0, 0, 0.12),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px rgba(0, 0, 0, 0.2);
  }
}
